<template>
    <el-dialog
        custom-class="customer-grade-edit"
        :title="isEdit ? '编辑职级' : '新增职级'"
        :show-close="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        v-loading="loading">
        <el-form
            ref="gradeEditForm"
            class="grade-edit-form"
            :model="gradeEditForm"
            label-width="100px"
            :rules="gradeEditFormRules">
            <el-form-item label="所属客户">
                <el-input v-model="customerName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item
                label="职级"
                prop="name">
                <el-input v-model="gradeEditForm.name" :maxlength="128" placeholder="请输入职级"></el-input>
            </el-form-item>
            <el-form-item
                label="薪酬区间"
                prop="salary"
                class="salary-item is-required">
                <el-input class="salary-input min-salary-input" v-model.number="gradeEditForm.minSalary">
                    <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                </el-input>
                <span>---</span>
                <el-input class="salary-input max-salary-input" v-model.number="gradeEditForm.maxSalary">
                    <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                </el-input>
            </el-form-item>
            <el-form-item
                label="相关补充"
                prop="discription">
                <el-input
                    type="textarea"
                    v-model="gradeEditForm.discription"
                    :rows="3"
                    :maxlength="200"
                    show-word-limit
                    placeholder="填写，如是否有股权激励等"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
            <el-button type="primary" @click="handleEditCustomerGrade">保存</el-button>
            <el-button @click="handleCloseDialog">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        customerId: {
            type: String,
            default: ""
        }, 
        customerName: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            dialogVisible: false,
            isEdit: false,
            loading: false,
            gradeEditForm: {
                name: "",
                salary: "",
                minSalary: "",
                maxSalary: "",
                discription: ""
            },
            gradeEditFormRules: {
                name: [{
                    required: true, message: '请输入职级', trigger: ['blur', 'change']
                }],
                salary: [{
                    validator:(rules, value, callback) => {
                        this.validSalary('salary', value, callback)
                    },
                    trigger: 'blur'
                }]
            }
        }
    },
    methods: {
        show(isEdit, json) {
            this.isEdit = isEdit;
            if(isEdit && json) {
                Object.assign(this.gradeEditForm, json);
            }
            if(!isEdit) {
                this.gradeEditForm.name = '';
                this.gradeEditForm.salary = '';
                this.gradeEditForm.minSalary = '';
                this.gradeEditForm.maxSalary = '';
                this.gradeEditForm.discription = '';
            }
            this.dialogVisible = true;
        },
        validSalary(rule, value, callback) {
            let minSalary = Number(this.gradeEditForm.minSalary),
                maxSalary = Number(this.gradeEditForm.maxSalary);
            if(isNaN(minSalary) || isNaN(maxSalary)) {
                callback('薪资要求必须为数字格式')
            } else if(!(minSalary >= 0 && minSalary < 1000)) {
                callback('最低月薪的取值范围是0-999')
            } else if(!(maxSalary >= 0 && maxSalary < 1000)) {
                callback('最高月薪的取值范围是0-999')
            } else if(minSalary > maxSalary) {
                callback('最低月薪不能大于最高月薪')
            } else {
                callback()
            }
        },
        handleEditCustomerGrade() {
            this.$refs.gradeEditForm.validate(valid => {
                if(valid) {
                    this.loading = true;
                    let json = Object.assign({}, this.gradeEditForm);
                    json.customerName = this.customerName;
                    delete json.updater
                    delete json.salary
                    let url = "/MyCompany/Customer/%p/Grade/CreateOrUpdate";

                    if(!this.isEdit) {
                        delete json.id;
                    }
                    _request({
                        url: url.replace(/%p/g, this.customerId),
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: json
                    }).then(res => {
                        this.loading = false;
                        if(!this.isEdit) {
                            shortTips('新增职级成功');
                        } else {
                            shortTips('修改成功')
                        }
                        this.handleCloseDialog();
                        this.$emit('update-list');
                    }).catch(err => {
                        this.loading = false;
                    })
                }
            })
        },
        handleCloseDialog() {
            this.$refs.gradeEditForm.resetFields();
            this.dialogVisible = false;
        }
    }
}
</script>

<style lang="scss" scope>
    .customer-grade-edit.el-dialog {
        width: 570px;
        .grade-edit-form.el-form {
            .el-form-item__label {
                text-align: left;
                margin-bottom: 0;
            }
            .el-form-item.salary-item {
                display: inline-block;
                .salary-input {
                    width: 205px;
                    &:nth-of-type(1) {
                        padding-right: 5px;
                    }
                    &:nth-of-type(2) {
                        padding-left: 5px;
                    }
                }
            }
        }
    }
</style>

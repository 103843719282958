<template>
  <div class="customer-grade-list">
    <el-table
      class="customer-grade-table"
      :data="gradeList"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="职级"
        width="100"
        class-name="name-column"
        :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        label="薪酬区间"
        width="120">
        <template slot-scope="scope">
          {{scope.row.minSalary}}K-{{scope.row.maxSalary}}K
        </template>
      </el-table-column>
      <el-table-column
        prop="discription"
        label="相关补充"
        :show-overflow-tooltip="false">
      </el-table-column>
      <el-table-column
        prop="updater"
        width="180"
        label="创建/更新人"
        :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        label="操作"
        width="130"
        class-name="grade-operate-menu"
        prop="id">
        <template slot-scope="scope">
          <span class="text-operate-btn" @click="editGrade(scope.row)">编辑</span>
          <span
            class="text-operate-btn"
            @click="deleteGrade(scope.row)"
            v-loading="scope.row.isDelete">
            删除
          </span>
        </template>
      </el-table-column>
      <template slot="empty">
        <div>
          <span class="empty-img"></span>
          <p class="empty-data">暂无数据</p>
        </div>
      </template>
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[10, 30, 50]"
      :page-size="pager.take"
      class="grade-list-pagination"
      layout="prev, pager, next, slot, total, sizes"
      :total="total"
      v-if="fromPage!='createJob' && total > pager.take"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
      <span class="pagination-text">
        <span>
          前往
          <el-input
            v-model="pagerJump"
            @keyup.enter.native="handlePagerJump(pagerJump)"
          ></el-input>
          页
        </span>
        <span @click="handlePagerJump(pagerJump)">
          跳转
        </span>
      </span>
    </el-pagination>
  </div>
</template>

<script>
import CustomerService from '#/js/service/customerService.js';
export default {
  name: "customerGradeList",
  props: {
    fromPage: String,
    gradeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    total: Number
  },
  data() {
    return {
      pager: {
        current: 1,
        take: 10
      },
      pagerJump: 0,
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pager.take = val;
      this.pager.current = 1;
      this.$emit('update-list', true);
    },
    handleCurrentChange(current) {
      this.pager.current = current;
      this.$emit('update-list', true);
    },
    handlePagerJump(pagerJump) {
      // if(pagerJump > Math.ceil(this.total/this.pager.size)) {
      //   return;
      // } else {
      //   this.handleCurrentChange(pagerJump);
      // }
      pagerJump = Number.parseInt(pagerJump);
      if (
        pagerJump > 0 &&
        pagerJump <= Math.ceil(this.pager.total / this.pager.take)
      ) {
        this.handleCurrentChange(pagerJump);
      }
    },
    editGrade(grade) {
      this.$emit('edit-grade', grade);
    },
    deleteGrade(grade) {
      this.$confirm(`确定删除职级<span class="text-primary">${grade.name}</span>吗？删除后不可恢复。`, '提示', {
        type: 'warning',
        showClose: false,
        customClass: 'grade-delete-dialog',
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(res => {
        this.$set(grade, 'isDelete', true)
        CustomerService.deleteGrade(grade.id)
        .then(res => {
          this.$set(grade, 'isDelete', false);
          shortTips('职级删除成功');
          this.$emit('update-list', false);
        }).catch(err => {
          this.$set(grade, 'isDelete', false);
        })
      }).catch(err => {})
    }
  }
}
</script>

<style lang="scss" scope>
.customer-grade-list {
  .customer-grade-table.el-table {
    .el-loading-mask {
      z-index: 900;
    }
    th {
      padding: 0;
      height: 42px;
      line-height: 42px;
      background-color: #f8f8f8;
      color: #444;
      font-weight: normal;
      &.name-column .cell {
        padding-left: 20px;
      }
    }
    td {
      padding: 0;
      height: 40px;
      line-height: 40px;
      color: #666;
      .cell {
        // white-space: nowrap;
      }
      &.name-column .cell {
        padding-left: 20px;
      }
      &.grade-operate-menu {
        .text-operate-btn + .text-operate-btn {
          margin-left: 10px;
        }
      }
    }
    .el-table__empty-block {
      .el-table__empty-text {
        line-height: inherit;
      }
      .empty-img {
        display: inline-block;
        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
        width: 150px;
        height: 150px;
      }
      .empty-data {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0px;
      }
    }
  }
  .grade-list-pagination.el-pagination {
      margin: 40px 0 20px 0;
      padding: 0;
      font-weight: normal;
      .btn-next, .btn-prev {
        height: 38px;
        border: 1px solid;
      }
      .btn-prev {
        border-right: none;
        border-radius: 4px 0 0 4px;
      }
      .btn-next {
        border-left: none;
        border-radius: 0 4px 4px 0;
      }
      .el-pagination__sizes .el-input{
        width: 87px;
        .el-input__inner {
          color: #666;
          .el-select__caret{
            color: #999;
          }
        }
      }
      .el-pager {
        border-top: 1px solid;
        border-bottom: 1px solid;
        li {
          min-width: 36px;
          height: 36px;
          line-height: 36px;
          font-size: 14px;
        }
      }
      .btn-next, .btn-prev, .el-pager {
        border-color: #DDDDDD;
      }
      .el-input__inner, .el-pagination__total, .el-pagination__jump {
        height: 38px;
        line-height: 38px;
        font-size: 14px;
      }
      .pagination-text{
        color: #999;
        span{
          height: 38px;
          line-height: 38px;
          font-size: 14px;
          .el-input{
            width: 48px;
            margin: 0 5px;
            &__inner{
              height: 38px;
              line-height: 38px;
              padding: 0 5px;
            }
          }
          &:nth-of-type(2){
            margin-left: 5px;
            color: $primary;
            cursor: pointer;
          }
        }
      }
      .el-pagination__total,
      .el-pagination__sizes,
      .pagination-text {
        float: right;
      }
    }
}
</style>

<style lang="scss">
  .grade-delete-dialog {
    .text-primary {
      color: $primary;
    }
  }
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "custom-class": "customer-grade-edit",
        title: _vm.isEdit ? "编辑职级" : "新增职级",
        "show-close": false,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "gradeEditForm",
          staticClass: "grade-edit-form",
          attrs: {
            model: _vm.gradeEditForm,
            "label-width": "100px",
            rules: _vm.gradeEditFormRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属客户" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.customerName,
                  callback: function ($$v) {
                    _vm.customerName = $$v
                  },
                  expression: "customerName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "职级", prop: "name" } },
            [
              _c("el-input", {
                attrs: { maxlength: 128, placeholder: "请输入职级" },
                model: {
                  value: _vm.gradeEditForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.gradeEditForm, "name", $$v)
                  },
                  expression: "gradeEditForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "salary-item is-required",
              attrs: { label: "薪酬区间", prop: "salary" },
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "salary-input min-salary-input",
                  model: {
                    value: _vm.gradeEditForm.minSalary,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeEditForm, "minSalary", _vm._n($$v))
                    },
                    expression: "gradeEditForm.minSalary",
                  },
                },
                [_c("template", { slot: "suffix" }, [_vm._v("K(千)  ")])],
                2
              ),
              _c("span", [_vm._v("---")]),
              _c(
                "el-input",
                {
                  staticClass: "salary-input max-salary-input",
                  model: {
                    value: _vm.gradeEditForm.maxSalary,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeEditForm, "maxSalary", _vm._n($$v))
                    },
                    expression: "gradeEditForm.maxSalary",
                  },
                },
                [_c("template", { slot: "suffix" }, [_vm._v("K(千)  ")])],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "相关补充", prop: "discription" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  maxlength: 200,
                  "show-word-limit": "",
                  placeholder: "填写，如是否有股权激励等",
                },
                model: {
                  value: _vm.gradeEditForm.discription,
                  callback: function ($$v) {
                    _vm.$set(_vm.gradeEditForm, "discription", $$v)
                  },
                  expression: "gradeEditForm.discription",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handleEditCustomerGrade },
            },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v("取消"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }